* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}
/* Custom CSS to target the time picker arrow */
.react-datepicker__time-arrow {
  font-size: 0.75rem; /* Smaller font size for the arrow */
}

/* Optional: Adjust the padding for the input container to fit the arrow */
.react-datepicker__input-container input {
  padding-right: 2rem; /* Ensure enough space for the arrow */
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	--ckeditor5-preview-sidebar-width: 270px;
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.main-container .presence {
	margin-bottom: 8px;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container__editor-wrapper {
	display: flex;
	width: fit-content;
}

.editor-container_include-outline .editor-container__editor .ck.ck-editor__editable {
	margin-left: 0;
}

.editor-container_include-annotations .editor-container__editor .ck.ck-editor__editable {
	margin-right: 0;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

.editor-container__sidebar {
	min-width: var(--ckeditor5-preview-sidebar-width);
	max-width: var(--ckeditor5-preview-sidebar-width);
	margin-top: 28px;
	margin-left: 10px;
	margin-right: 10px;
}

.revision-history {
	display: none;
}

.revision-history__wrapper {
	display: flex;
}

.revision-history__wrapper .ck.ck-editor {
	margin: 0;
	width: 795px;
}

.revision-history__wrapper .revision-history__sidebar {
	border: 1px solid var(--ck-color-base-border);
	border-left: 0;
	width: var(--ckeditor5-preview-sidebar-width);
	min-height: 100%;
}

.revision-history__wrapper .revision-history__sidebar .ck-revision-history-sidebar {
	height: 100%;
}
